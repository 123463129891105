import React from 'react'
import Loader from './loader'
import Header from './header'
import Navbar from './navbar'
import { Helmet } from 'react-helmet'
import logo from '../images/logo.png'
import logo2 from '../images/imageog.png'

const Layout = ({pageTitle, children}) => {

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle}</title>

<meta name="description" content="Offshore software development and IT services company in Sri Lanka for all your outsourcing needs. Services include website, web application, ecommerce and mobile app development to it infrastructure implementation, management and support" />


<meta property="og:url" content="https://cognizant.pragicts.com/" />
<meta property="og:type" content="website" />
<meta property="og:title" content="PragICTS | Cognizant" />
<meta property="og:description" content="PragICTS provides tailored e-commerce design, implementation, management and support services for companies of all scales." />
<meta property="og:image" content="https://s3.ap-southeast-1.amazonaws.com/itops.pragicts.com/1.png" />
<meta name="keywords" content="OpenCart, Bagisto, WooCommerce, Ecommere Design, Ecommerce Implementation, Ecommerce Management, Ecommerce Support, Master Payment Gateway Integration, VISA Payment Gateway Integration, VISA Cyber Source Integration" />

<meta name="twitter:card" content="summary_large_image" />
<meta property="twitter:domain" content="cognizant.pragicts.com" />
<meta property="twitter:url" content="https://cognizant.pragicts.com/" />
<meta name="twitter:title" content="PragICTS | Cognizant" />
<meta name="twitter:description" content="Offshore software development and IT services company in Sri Lanka for all your outsourcing needs. Services include website, web application, ecommerce and mobile app development to it infrastructure implementation, management and support" />
<meta name="twitter:image" content="https://s3.ap-southeast-1.amazonaws.com/itops.pragicts.com/1.png" />




                <link rel="canonical" href="https://pragicts.com" />
                <script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js"></script> 
            </Helmet>
            <Loader />
            <div id="main">
                <Header />
                <Navbar />
                {children}
            </div>
        </>
    )
}

export default Layout
